import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseDialog } from "./dialog";
import { Button } from "@/components/ui/button";
import { uploadArifactForClient } from "@/service/weexpertService";
import { toast } from "../ui/use-toast";
import { SelectWithAlert } from "../ui/select-with-alert";
import { CLIENT_ARTIFACT_TYPES } from "@/constants";
import { useQueryClient } from "react-query";

export default function UploadArtifactForClient(props) {
  
  const { id } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const fileInputRef = useRef(null);
  const [file, setFile] = useState();
  const queryClient = useQueryClient();

  const handleUpload = () => {
    
    if (!selectedType) {
      return;
    }
    if (!file) {
      toast({
        title: "Please upload a file before submitting.",
        variant: "destructive",
      });
      return;
    }
    uploadArifactForClient(id, file, selectedType)
      .then(() => {
        toast({
          title: "File uploaded successfully!",
          description: "Your document has been uploaded.",
        });
        queryClient.invalidateQueries(['artefacts', id]);
        setOpen(false);
        setSelectedType("");
        
      })
      .catch(() => {
        toast({
          title: "Oops something went wrong",
          variant: "destructive",
        });
      });
  };

  return (
    <BaseDialog
      dialogTitle={""}
      buttonText={t("common:button_upload")}
      open={open}
      setOpen={setOpen}
    >
      <div className="w-[400px] p-1">
        <div className="pt-4">
          <SelectWithAlert
            inputClassName={"h-10 mt-2 mb-4 bg-white"}
            label={t("common:select_type_of_document")}
            placeholder={t("common:select_type_of_document")}
            value={selectedType}
            onChangeValue={(e) => setSelectedType(e)}
            options={CLIENT_ARTIFACT_TYPES(t)}
            error={!setSelectedType}
            errorMessage={"Please select a document type"}
          />
        </div>
        <div className="w-full flex justify-between pt-4">
          <div>
            <input
              ref={fileInputRef}
              accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.png,.jpg"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
              type="file"
              className="block text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                           file:rounded-sm file:border-0 file:text-sm file:font-semibold
                           file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end pt-4">
        <Button onClick={handleUpload}>{t("common:button_upload")}</Button>
      </div>
    </BaseDialog>
  );
}
