import { Button } from "@/components/ui/button";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { SelectWithAlert } from "@/components/ui/select-with-alert";
import {
  EW_REPORT_STATE,
  REPRESENTATIVE_COMPANY_LIST,
  transformFromIdValueToValueLabel,
} from "@/constants";
import { editBankReport, getReport, getUser } from "@/service/weexpertService";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { Loader } from "lucide-react";
import { DatePicker } from "@/components/ui/date-picker";
import { useState } from "react";
import { toast } from "@/components/ui/use-toast";
const ReportEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [representativeCompany, setRepresentativeCompany] = useState(null);
  const [reportState, setReportState] = useState(null);

  const { data: report, isLoading } = useQuery({
    queryKey: ["report", id],
    queryFn: () => getReport(id),
    onSuccess: (res) => {
      const { report } = res.data;
      setTitle(report.title);
      setFromDate(new Date(report.fromDate));
      setEndDate(new Date(report.toDate));
      setRepresentativeCompany(report.representativeCompany);
      setReportState(report.state);
    },
    retry: false,
  });

  const { data: user, isLoading: isUserLoading } = useQuery({
    queryKey: "userMe",
    queryFn: getUser,
    retry: false,
  });

  const reportData = report?.data?.report;
  const userRole = user?.data.roles;
  if (userRole === "CLIENT") {
    navigate("/404");
  }
  return (
    <>
      {isUserLoading ? (
        <Loader />
      ) : (
        <div className="w-full relative bg-grayLightMainBg px-8">
          <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
            <div className="flex justify-between pt-8  items-center ">
              <div className=" w-5/12  text-4xl ">
                {t("reports:report_title") + ":"} {id}
              </div>
              <div>
                <div className="flex justify-between">
                  <Button className="mr-2" onClick={() => {
                    navigate(-1)
                  }}>
                    {t("back")}
                  </Button>
                  <Button className="mr-2" onClick={() => {
                    if (!fromDate || !endDate || !representativeCompany || title === "") {
                      toast({
                        title: t("alerts:toast_error_counterparty_title"),
                        description: t("alerts:toast_error_counterparty_description"),
                        variant: "destructive",
                      });
                      return;
                    }
                    editBankReport(
                      id,
                      title,
                      format(fromDate, "yyyy-MM-dd"),
                      format(endDate, "yyyy-MM-dd"),
                      representativeCompany,
                      reportState
                    )
                      .then((response) => {
                        if (response.status === 200) {
                          toast({
                            title: t("alerts:toast_succes_invoice_create_description"),
                            description: t("alerts:toast_succes_invoice_create_description"),
                          });
                          setTimeout(() => {
                            navigate("/reports");
                          }, 1000);
                        }
                      })
                      .catch(() => {
                        toast({
                          title: t("alerts:toast_error_counterparty_title"),
                          description: t("alerts:toast_error_invoice_description"),
                          variant: "destructive",
                        });
                      });
                  }}>
                    {t("button_save")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {!isLoading && (
            <>
              <div className="pt-16">
                <div className="w-4/12 pb-4">
                  <InputWithAlert
                    label={t("counterparties:name")}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="w-4/12 pb-4">
                  <DatePicker
                    date={fromDate}
                    setDate={(e) => setFromDate(e)}
                    label={t("reports:date_from")}
                    inputClassName={"h-12"}
                  />
                </div>
                <div className="w-4/12 pb-4">
                  <DatePicker
                    date={endDate}
                    setDate={(e) => setEndDate(e)}
                    label={t("reports:date_to")}
                    inputClassName={"h-12"}
                  />
                </div>
                <div className="w-4/12 pb-4">
                  <SelectWithAlert
                    label={t("clients:representative_company")}
                    value={representativeCompany}
                    options={REPRESENTATIVE_COMPANY_LIST.map(
                      transformFromIdValueToValueLabel,
                    )}
                    onChangeValue={(e) => setRepresentativeCompany(e)}
                    inputClassName={"h-12 mt-2 mb-4 bg-white"}
                  />
                </div>
                <div className="w-4/12 pb-4">
                  <SelectWithAlert
                    label={t("state")}
                    value={reportState}
                    options={EW_REPORT_STATE}
                    inputClassName={"h-12 mt-2 mb-4 bg-white"}
                    onChangeValue={(e) => setReportState(e)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default ReportEditPage;
