import { Button } from "@/components/ui/button";
import {
  deleteBankTransactionExplanation,
  deletePlatformExplanation,
  disconnectInvoiceFromTransaction,
  getTransaction,
  getUser,
} from "@/service/weexpertService";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeleteIcon, Loader, TrashIcon } from "lucide-react";
import { useQuery } from "react-query";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { Textarea } from "@/components/ui/textarea";
import { formatDateString } from "@/styles";

import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { v4 as uuidv4 } from "uuid";
import TransactionConnect from "@/components/popups/transaction.connect.popup";
import { BankTransactionExplanationEditPopup } from "@/components/popups/Bank-transaction-explanation.edit.popup";
import { BankTransactionExplanationCreatePopup } from "@/components/popups/Bank-transaction-explanation.create.popup";
import { toast } from "@/components/ui/use-toast";
import {
  BankPlatformExplanationCreatePopup,
  BankPlatformExplanationEditPopup,
} from "@/components/popups/platform-explanation.create.popop";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
const BankTransactionView = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const {
    data: transaction,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["transaction", id],
    queryFn: () => getTransaction(id),
    retry: false,
  });

  const { data: user, isLoading: isUserLoading } = useQuery({
    queryKey: "userMe",
    queryFn: getUser,
    retry: false,
  });

  const userRole = user?.data?.roles;
  const transactionData = transaction?.data?.transactions ?? null;
  const bankExplanationData =
    transactionData?.bankRecordTransactionExplanation ?? [];

  const platformEvidences =
    transactionData?.bankRecordPlatformExplanations ?? [];
  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }) => row.original.id,
      key: uuidv4(),
    },
    {
      accessorKey: "counterparty",
      header: t("counterparty"),
      cell: ({ row }) => row.original.counterparty,
      key: uuidv4(),
    },
    {
      accessorKey: "title",
      header: t("counterparties:name"),
      cell: ({ row }) => row.original.title,
      key: uuidv4(),
    },
    {
      accessorKey: "transactionType",
      header: t("reports:transaction_type"),
      cell: ({ row }) => row.original.transactionType,
      key: uuidv4(),
    },
    {
      accessorKey: "currency",
      header: t("invoices:currency"),
      cell: ({ row }) => row.original.currency,
      key: uuidv4(),
    },
    {
      accessorKey: "vat",
      header: "VAT",
      cell: ({ row }) => row.original.vat,
      key: uuidv4(),
    },
    {
      accessorKey: "vatAmount",
      header: t("invoices:positions_vat"),
      cell: ({ row }) => row.original.vatAmount.toFixed(2),
      key: uuidv4(),
    },
    {
      accessorKey: "netAmount",
      header: t("invoices:price_netto"),
      cell: ({ row }) => row.original.netAmount.toFixed(2),
      key: uuidv4(),
    },
    {
      accessorKey: "grossAmount",
      header: t("invoices:price_brutto"),
      cell: ({ row }) => row.original.grossAmount,
      key: uuidv4(),
    },
    {
      accessorKey: "date",
      header: t("invoices:create_date"),
      cell: ({ row }) => row.original.date,
      key: uuidv4(),
    },
    {
      accessorKey: "actions",
      header: t("action"),
      cell: ({ row }) => (
        <div className="flex items-center">
          <BankTransactionExplanationEditPopup
            initialExplanationId={row.original.id}
            explanationData={row.original}
          />
          <Button
            variant="outline"
            onClick={() => {
              deleteBankTransactionExplanation(row.original.id).then(() => {
                refetch();
              });
            }}
          >
            <TrashIcon />
          </Button>
        </div>
      ),
    }
  ];

  const platformColums = [
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }) => row.original.id,
      key: uuidv4(),
    },
    {
      accessorKey: "counterparty",
      header: t("counterparty"),
      cell: ({ row }) => row.original.counterparty,
      key: uuidv4(),
    },
    {
      accessorKey: "invoice",
      header: t("platfom_form_input_invoice_id"),
      cell: ({ row }) => row.original.invoice ?? "-",
      key: uuidv4(),
    },
    {
      accessorKey: "invoiceAmount",
      header: t("platfom_form_input_invoice_amount"),
      cell: ({ row }) => row.original.invoiceSum ?? "-",
      key: uuidv4(),
    },
    {
      accessorKey: "currency",
      header: t("invoices:currency"),
      cell: ({ row }) => row.original.invoiceCurrency ?? "-",
      key: uuidv4(),
    },
    {
      accessorKey: "costInvoice",
      header: t("platfom_form_input_cost_invoice_id"),
      cell: ({ row }) => row.original.costInvoice ?? "-",
      key: uuidv4(),
    },
    {
      accessorKey: "costInvoiceAmount",
      header: t("platfom_form_input_cost_invoice_amount"),
      cell: ({ row }) => row.original.costInvoiceSum ?? "-",
      key: uuidv4(),
    },
    {
      accessorKey: "costCurrency",
      header: t("invoices:currency"),
      cell: ({ row }) => row.original.costInvoiceCurrency ?? "-",
      key: uuidv4(),
    },
    {
      accessorKey: "platform",
      header: t("platfom_form_input_platform"),
      cell: ({ row }) => row.original.platformType,
      key: uuidv4(),
    },
    {
      accessorKey: "actions",
      header: t("action"),
      cell: ({ row }) => (
          <div className="flex items-center">
            <BankPlatformExplanationEditPopup platformExplanation={row.original} />
            <Button
              variant="outline"
              onClick={() => {
                return deletePlatformExplanation(row.original.id).then(() => {
                  refetch();
                });
              }}
            >
              <TrashIcon />
            </Button>
          </div>
      ),
    },
  ];

  const table = useReactTable({
    data: isLoading ? Array(30).fill({}) : bankExplanationData,
    columns: isLoading
      ? columns.map((column) => {
          return {
            ...column,
            cell: () => {
              return <Skeleton className="h-4 w-[200px]" />;
            },
          };
        })
      : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const platformEvidenceTable = useReactTable({
    data: isLoading ? Array(30).fill({}) : platformEvidences,
    columns: isLoading
      ? platformColums.map((column) => {
          return {
            ...column,
            cell: () => {
              return <Skeleton className="h-4 w-[200px]" />;
            },
          };
        })
      : platformColums,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  if (userRole === "CLIENT") {
    navigate("/404");
  }

  return isUserLoading || isLoading || !!!transactionData ? (
    <Loader />
  ) : (
    <div className="w-full relative bg-grayLightMainBg px-8 pb-16">
      <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
        <div className="flex justify-between pt-8  items-center ">
          <div className=" w-5/12  text-4xl pb-4 ">
            {t("reports:transaction_details") + ":"} {id}
          </div>
          <div>
            <div className="flex justify-between">
             {transactionData.invoice && (
                <Button
                  variant="outline"
                  className="mr-4"
                  onClick={() => {
                    disconnectInvoiceFromTransaction(transactionData.id).then(
                      () => {
                        toast({
                          title: "Ok",
                        });
                        refetch();
                      },
                    );
                  }}
                >
                  Отвязать фактуру
                </Button>
              )}
              <TransactionConnect
                transactionId={id}
                reportId={transactionData.bankRecordsReport.id}
              />
              <Button className="min-w-20" onClick={goBack}>
                {t("button_back")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-12/12 lg:w-9/12 xl:w-9/12 pt-16">
        <div className="w-full flex justify-between">
          <div className="w-[49%]">
            <div className="pb-4">
              <InputWithAlert
                label={t("invoices:payment_account")}
                value={transactionData.accountNumber}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label={t("invoices:price_brutto")}
                value={transactionData.amount}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label={t("reports:booking_date")}
                value={transactionData.bookingDate}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label={t("counterparty")}
                value={transactionData.contractor}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label={t("reports:contractor_account")}
                value={transactionData.contractorAccountNumber}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label={t("reports:transaction_description")}
                value={transactionData.transactionDescription}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label={t("reports:transaction_ID")}
                value={transactionData.transactionID}
                disabled={true}
              />
            </div>
          </div>
          <div className="w-[49%]">
            <div className="pb-4">
              <InputWithAlert
                label={t("invoices:currency")}
                value={transactionData.currency}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label={t("invoices:payment_currency_date")}
                value={transactionData.currencyDate}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label={t("client")}
                value={transactionData.clientName}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label="mBankCode"
                value={transactionData.mBankCode}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label={t("reports:record_imported_At")}
                value={formatDateString(transactionData.recordImportedAt)}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label={t("reports:transaction_code")}
                value={transactionData.transactionCode}
                disabled={true}
              />
            </div>
            <div className="pb-4">
              <InputWithAlert
                label={t("reports:transaction_side")}
                value={transactionData.transactionSide}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between">
          <div className="w-[49%]">
            <div className="pb-4">
              <InputWithAlert
                label={"CLIENT"}
                value={
                  transactionData.client
                    ? `${transactionData.client.firstName} ${transactionData.client.lastName}`
                    : "-"
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="w-[49%]">
            <div className="pb-4">
              <InputWithAlert
                label={"INVOICE"}
                value={
                  transactionData.invoice
                    ? transactionData.invoice.invoiceId
                    : "-"
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-4">
        <div className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-2">
          {t("reports:full_transaction_description")}
        </div>
        <Textarea
          readOnly={true}
          disabled
          value={transactionData.fullTransactionDescription}
          className="h-20 bg-white text-gray-400"
        />
      </div>
      <div className="w-full flex justify-end items-center pt-4 pb-8">
        <BankPlatformExplanationCreatePopup transactionId={id} />
        {userRole !== "CLIENT" && (
          <BankTransactionExplanationCreatePopup
            initialTransactionId={id}
            initialTransactionData={transactionData}
          />
        )}
      </div>
      <Tabs defaultValue="evidence">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="evidence">{t("bank_report_platform_evidence")}</TabsTrigger>
          <TabsTrigger value="platform_evidence">{t("platform_pop_up_button")}</TabsTrigger>
        </TabsList>
        <TabsContent value="evidence">
          <div className="w-fullpy-4 bg-grayLightMainBg">
            <div className="rounded-md border p-2">
              {
                <Table>
                  <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={uuidv4()}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <TableHead className="pt-3 pb-3" key={uuidv4()}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                            </TableHead>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {table.getRowModel().rows?.length ? (
                      table.getRowModel().rows.map((row) => (
                        <TableRow
                          key={row.id}
                          data-state={row.getIsSelected() && "selected"}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <TableCell
                              className="relative text-sm pt-3 pb-3"
                              key={uuidv4()}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          className="h-24 text-center"
                        >
                          {t("no_results")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              }
            </div>
          </div>
        </TabsContent>

        <TabsContent value="platform_evidence">
          <div className="w-fullpy-4 bg-grayLightMainBg">
            <div className="rounded-md border p-2">
              {
                <Table>
                  <TableHeader>
                    {platformEvidenceTable
                      .getHeaderGroups()
                      .map((headerGroup) => (
                        <TableRow key={uuidv4()}>
                          {headerGroup.headers.map((header) => {
                            return (
                              <TableHead className="pt-3 pb-3" key={uuidv4()}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext(),
                                    )}
                              </TableHead>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableHeader>
                  <TableBody>
                    {platformEvidenceTable.getRowModel().rows?.length ? (
                      platformEvidenceTable.getRowModel().rows.map((row) => (
                        <TableRow
                          key={row.id}
                          data-state={row.getIsSelected() && "selected"}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <TableCell
                              className="relative text-sm pt-3 pb-3"
                              key={uuidv4()}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={platformColums.length}
                          className="h-24 text-center"
                        >
                          {t("no_results")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              }
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};
export default BankTransactionView;
