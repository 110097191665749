import { INVOICE_CREATE, InvoiceForm } from "@/components/forms/invoice.form";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { createInvoice } from "../service/weexpertService";
import { toast } from "@/components/ui/use-toast";
import { useTranslation } from "react-i18next";
import { INVOICE_TYPE, INVOICE_TYPES } from "@/constants";

const InvoiceCreatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inputChecked, setInputChecked] = useState(false);

  return (
    <div className="relative bg-grayLightMainBg px-8">
      <InvoiceForm action={INVOICE_CREATE}
        inputChecked={inputChecked}
        onSave={(invoice) => {
          const {
            client,
            invoiceType,
            issueDate,
            domainDate,
            status,
            counterpartyName,
            counterpartyOrigin,
            counterpartyNip,
            counterpartyEmail,
            counterpartyType,
            counterpartyAddress,
            counterpartyPostIndex,
            counterpartyCity,
            counterpartyCountry,
            typeOfPayment,
            wayOfPayment,
            paymentStatus,
            paymentDueDate,
            paymentCurrency,
            paymentCurrencyRate,
            paymentCurrencyRateDate,
            paymentIban,
            internalComment,
            externalComment,
            rows,
            invoiceBrutto,
            invoiceTotalNetto,
            invoiceVAT,
            useDifferentRecipient,
            recipientCounterparty
          } = invoice;
          setInputChecked(true);
          if (!client) {
            return;
          }
          if (useDifferentRecipient && !recipientCounterparty) {
            return;
          }

          if (!invoiceType) {
            return;
          }
          if (!issueDate) {
            return;
          }
          if (!domainDate) {
            return;
          }
          if (!status) {
            return;
          }
          if (!counterpartyName) {
            return;
          }
          if (!counterpartyType) {
            return;
          }
          if (!typeOfPayment) {
            return;
          }
          if (rows.some((e) => e.name === '' || e.quantity === '' || (invoiceType !== INVOICE_TYPES.FAKTURA_KONCOWA && !e.unitPriceNetto) || e.vatRate === '' || e.unit === '') ||
            rows.length == 0
          ) {
            return;

          }
          return createInvoice(
            invoiceType,
            domainDate,
            status,
            counterpartyName,
            counterpartyType,
            counterpartyOrigin,
            counterpartyName,
            counterpartyPostIndex,
            counterpartyNip,
            counterpartyCity,
            counterpartyEmail,
            counterpartyCountry,
            counterpartyAddress,
            /////////////
            typeOfPayment,
            wayOfPayment,
            paymentStatus,
            paymentDueDate,
            paymentCurrency,
            paymentCurrencyRate,
            paymentIban,
            invoiceTotalNetto,
            invoiceBrutto,
            invoiceVAT,
            client.id,
            rows,
            internalComment,
            externalComment,
            paymentCurrencyRateDate,
            issueDate,
            useDifferentRecipient,
            {
              counterpartyType: recipientCounterparty.counterpartyType,
              name: recipientCounterparty.counterpartyName,
              address: recipientCounterparty.counterpartyAddress,
              postIndex: recipientCounterparty.counterpartyPostIndex,
              city: recipientCounterparty.counterpartyCity,
              email: recipientCounterparty.counterpartyEmail,
              country: recipientCounterparty.counterpartyCountry,
              NIP: recipientCounterparty.counterpartyNip,
              origin: recipientCounterparty.counterpartyOrigin
            }
          )
            .then((response) => {
              if (response.status === 200) {
                toast({
                  title: t("alerts:toast_succes_invoice_create_title"),
                  description:t("alets:toast_succes_invoice_create_description"),
                })
                navigate('/admin/invoices/sales')
              }
            })
            .catch((error) => {
              toast({
                title: t("alerts:toast_error_invoice_title"),
                description: t("alerts:toast_error_invoice_description"),
                variant: "destructive"
              })

              if (error.response && error.response.status === 400) {
                console.error(error);
              } else {
                console.error(error);
              }
            });
        }} />
    </div>
  )

}
export default InvoiceCreatePage;
