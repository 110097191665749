import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Alert } from "@mui/material";
import { getUser, updateUser } from "../../service/weexpertService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  validateOnlyLatin,
  validatePhone,
  validateEmail,
} from "../../constants";
import { InputWithAlert } from "../ui/input-with-alert";
import { Button } from "@/components/ui/button";
import { rolesForUsersCreate } from "../../constants";

const AccountForm = (props) => {
  const { t } = useTranslation();
  const { userData, handleNewPass } = props;
  const token = Cookies.get("token");
  const [alertMessage, setAlertMessage] = useState("");
  const [inputValueName, setInputValueName] = useState("");
  const [inputValueSurname, setInputValueSurname] = useState("");
  const [mail, setMail] = useState("");
  const [tel, setTel] = useState("");

  const alertMessagehandler = (value) => {};

  const getUserInfo = async () => {
    try {
      const response = await getUser();
      const user = response.data;
      setInputValueName(user.firstName);
      setInputValueSurname(user.lastName);
      setMail(user.email);
      setTel(user.phone);
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    getUserInfo();
  }, [token]);

  const cleanValues = () => {
    setInputValueName("");
    setInputValueSurname("");
    setMail("");
    setTel("");
  };

  const setAndValidateName = (newValue) => {
    const isValid = validateOnlyLatin(newValue);
    if (!isValid && newValue.length !== 0) {
      setAlertMessage(alertErrorIncorrectText);
    } else {
      setAlertMessage("");
    }
    setInputValueName(newValue);
  };

  const setAndValidateSurname = (newValue) => {
    const isValid = validateOnlyLatin(newValue);
    if (!isValid && newValue.length !== 0) {
      setAlertMessage(alertErrorIncorrectText);
    } else {
      setAlertMessage("");
    }
    setInputValueSurname(newValue);
  };

  const setAndValidatePhone = (newValue) => {
    const isValid = validatePhone(newValue);
    if (!isValid && newValue.length !== 0) {
      setAlertMessage(alertErrorTel);
    } else {
      setAlertMessage("");
    }
    setTel(newValue);
  };

  const setAndValidateEmail = (newValue) => {
    const isValid = validateEmail(newValue);
    setMail(newValue);
    if (!isValid && newValue.length !== 0) {
      setAlertMessage(alertErrorMail);
    } else setAlertMessage("");
  };

  const onClickSendButtonHandler = () => {
    updateUser(inputValueName, inputValueSurname, tel, mail)
      .then((response) => {
        const user = response.data;
        Cookies.set("token", `${user.access_token}`, { expires: 1 });
        navigate("/account");
        alertMessagehandler(alertSuccess);
        cleanValues();
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          alertMessagehandler(alertErrorAsses);
        } else {
          console.error(error);
        }
      });
  };

  const alertErrorIncorrectText = (
    <Alert severity="error">{t("alerts:error_words_lang_wrong_input")}</Alert>
  );
  const alertErrorMail = (
    <Alert severity="error">{t("alerts:error_format_email")}</Alert>
  );
  const alertSuccess = (
    <Alert severity="success">{t("alerts:success_change_data")}</Alert>
  );
  const alertErrorAsses = (
    <Alert severity="error">{t("alerts:error_send_fail")}</Alert>
  );
  const alertErrorTel = (
    <Alert severity="error">{t("alerts:error_phone_validation")}</Alert>
  );

  return (
    <>
      <div className="w-full min-h-[95vh] px-8 py-10 bg-grayLightMainBg">
        <div className="w-full flex justify-between items center pt-2 pb-4">
          <div className="text-4xl ">{t("users:account_title")}</div>
          <div>
            <Button
              className="mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
              onClick={handleNewPass}
            >
              {t("users:change_pass")}
            </Button>
            <Button
              className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
              onClick={() => {
                onClickSendButtonHandler();
              }}
            >
              {t("button_save")}
            </Button>
          </div>
        </div>
        <div className="text-xl pt-6">
          {`${userData.firstName} ${userData.lastName}  `}
        </div>
        <div className="text-base opasity-60 pt-2">
          {
            rolesForUsersCreate(t).find((e) => e.value === userData.roles)
              ?.display
          }
        </div>
        <div className="w-5/12 pt-8 pb-16">
          <InputWithAlert
            label={t("name")}
            value={inputValueName}
            onChange={(e) => setAndValidateName(e.target.value)}
            errorMessage={t("empty_data_invalid")}
            inputClassName={"h-12 mt-2 mb-4 bg-white"}
          />
          <InputWithAlert
            label={t("last_name")}
            value={inputValueSurname}
            onChange={(e) => setAndValidateSurname(e.target.value)}
            errorMessage={t("empty_data_invalid")}
            inputClassName={"h-12 mt-2 mb-4 bg-white"}
          />
          <InputWithAlert
            label={t("phone")}
            value={tel}
            onChange={(e) => setAndValidatePhone(e.target.value)}
            errorMessage={t("empty_data_invalid")}
            inputClassName={"h-12 mt-2 mb-4 bg-white"}
          />
          <InputWithAlert
            label="Email"
            value={mail}
            onChange={(e) => setAndValidateEmail(e.target.value)}
            errorMessage={t("empty_data_invalid")}
            inputClassName={"h-12 mt-2 mb-4 bg-white"}
          />
        </div>
      </div>
    </>
  );
};

export default AccountForm;
