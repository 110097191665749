import { BankPlatformExplanationEditPopup } from "@/components/popups/platform-explanation.create.popop";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  deletePlatformExplanation,
  getReport,
  getUser,
} from "@/service/weexpertService";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TrashIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { BankReportTitle } from "./bank-report-transactions.pages";
import { DataTablePagination } from "@/components/ui/table-pagination";
import { Loader } from "@/components/ProtectedRoute";

export const BankReportPlatformEvidencesPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    data: report,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["transaction", id],
    queryFn: () => getReport(id),
    retry: false,
  });
  const { data: user, isLoading: isUserLoading } = useQuery({
    queryKey: "userMe",
    queryFn: getUser,
    retry: false,
  });

  const platformColums = [
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }) => row.original.id,
    },
    {
      accessorKey: "counterparty",
      header: t("counterparty"),
      cell: ({ row }) => row.original.counterparty,
    },
    {
        accessorKey: "bankAccount",
        header: t("platfom_form_input_bank_account"),
        cell: ({ row }) => row.original.bankAccount,
      },
    {
      accessorKey: "invoice",
      header: t("bank_report_platform_table_header_invoice"),
      cell: ({ row }) => row.original.invoice ?? "-",
    },
    {
      accessorKey: "invoiceAmount",
      header: t("platfom_form_input_invoice_amount"),
      cell: ({ row }) => row.original.invoiceSum ?? "-",
    },
    {
      accessorKey: "currency",
      header: t("invoices:currency"),
      cell: ({ row }) => row.original.invoiceCurrency ?? "-",
    },
    {
      accessorKey: "costInvoice",
      header: t("common:platfom_form_input_cost_invoice_id"),
      cell: ({ row }) => row.original.costInvoice ?? "-",
    },
    {
      accessorKey: "costInvoiceAmount",
      header: t("platfom_form_input_cost_invoice_amount"),
      cell: ({ row }) => row.original.costInvoiceSum ?? "-",
    },
    {
      accessorKey: "costCurrency",
      header: t("platfom_form_input_cost_currency"),
      cell: ({ row }) => row.original.costInvoiceCurrency ?? "-",
    },
    {
      accessorKey: "platform",
      header: t("platfom_form_input_platform"),
      cell: ({ row }) => row.original.platformType,
    },
    {
      accessorKey: "assistant",
      header: t("assistant"),
      cell: ({ row }) => `${row.original.assistant.firstName} ${row.original.assistant.lastName}`,
    },

    {
      accessorKey: "actions",
      header: t("action"),
      cell: ({ row }) => (
        <div className="flex items-center">
          <BankPlatformExplanationEditPopup
            platformExplanation={row.original}
            bankReportId={id}
          />
          <Button
            variant="outline"
            onClick={() => {
              return deletePlatformExplanation(row.original.id).then(() => {
                refetch();
              });
            }}
          >
            <TrashIcon />
          </Button>
        </div>
      ),
    },
  ];

  const platformEvidenceTable = useReactTable({
    data: isLoading
      ? Array(30).fill({})
      : report.data.report.bankRecordPlatformExplanation ?? [],
    columns: isLoading
      ? platformColums.map((column) => {
          return {
            ...column,
            cell: () => {
              return <Skeleton className="h-4 w-[200px]" />;
            },
          };
        })
      : platformColums,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 50
      }
    }
  });
  if(isLoading || isUserLoading) {
    return <Loader/>
  }
  return <div className="relative">
    <div className="sticky left-0 z-10 w-full bg-grayLightMainBg pl-8 pr-8">
      <BankReportTitle reportId={id} />
      <div className="w-fullpy-4 bg-grayLightMainBg">
        <div className="rounded-md border p-2">
          {
            <Table>
              <TableHeader>
                {platformEvidenceTable.getHeaderGroups().map((headerGroup) => (
                  <TableRow>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead className="pt-3 pb-3">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {platformEvidenceTable.getRowModel().rows?.length ? (
                  platformEvidenceTable.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={row.id + cell.id}
                          className="relative text-sm pt-3 pb-3"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={platformColums.length}
                      className="h-24 text-center"
                    >
                      {t("no_results")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          }
        </div>
        <DataTablePagination table={platformEvidenceTable} />
      </div>
    </div>
  </div>;
};
