import { Loader } from "lucide-react"
import { AnnexView } from "@/components/annex/annex.view"
import { ContractForm } from "@/components/forms/contract.form"
import AnnexCreatePopup from "@/components/popups/annex.create.popup"
import CreateBillPopup from "@/components/popups/create.bill.popup"
import { Button } from "@/components/ui/button"
import { toast } from "@/components/ui/use-toast"
import { createAnnex, getContract, getContractEndPdf, getContractPdf, getUser, sendContractToHR } from "@/service/weexpertService"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import * as Sentry from "@sentry/react";
import { CONTRACT_STATE } from "@/constants"

export const ContractViewPage = () => {

  const { data: user, isLoading: isUserLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: contract, isLoading } = useQuery({
    queryKey: ['contract', id], queryFn: () => {
      return getContract(id)
    }
  });
  if (isLoading || isUserLoading) {
    return <Loader />
  }
  const userRole = user.data.roles;

  return <div className="relative bg-grayLightMainBg px-8">
    <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
      <div className="flex justify-between py-8 items-center ">
        <div className=" w-5/12  text-4xl ">
          {t('contracts:contract_details')}
        </div>
        <div className='w-6/12 flex justify-end'>
          {userRole !== 'CLIENT' && (
            <>
              {contract.data.contract.state !== CONTRACT_STATE.Ended &&
                      <Button className=" text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none mr-2"
                        onClick={() => {
                          sendContractToHR(contract.data.contract.id)
                            .then(() => {
                              toast({
                                title: "Отправлено"
                              })
                            })
                            .catch((e) => {
                              if(e.response.data.message === "MISSING_PASSPORT") {
                                toast({
                                  title: "Загрузите пасспорт к клиенту",
                                  variant: 'destructive'
                                });
                                return;
                              }
                              if(e.response.data.message === "MISSING_QUESTIONARY") {
                                toast({
                                  title: "Загрузите опросник к клиенту",
                                  variant: 'destructive'
                                })
                                return;
                              }
                              toast({
                                title: "Ошибка",
                                variant: 'destructive'
                              })
                            })
                        }}
                      >
                       HR mail
                     </Button>
                   }

              {contract.data.contract.state !== CONTRACT_STATE.Ended &&
              <CreateBillPopup
                initialContract={contract.data.contract}
              />
            }
              <AnnexCreatePopup
                text={t("contracts:annex")}
                initialContract={contract.data.contract}
                callback={createAnnex}
              />
              {contract.data.contract.state === CONTRACT_STATE.Ended &&
                <Button className=" text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none mr-2"
                  onClick={() => {
                    toast({
                      title: t("generation_pdf_started")
                    })
                    getContractEndPdf(id, contract.data.contract.contractId).
                      then(() => {
                        toast({
                          title: t("generation_pdf_success")
                        })
                      })
                      .catch((res) => {
                        Sentry.captureException(res);
                        toast({
                          title: t("generation_pdf_error"),
                          variant: "destructive"
                        })
                      })
                  }} >
                  ROZWIĄZNIE UMOWY PDF
                </Button>
              }
            </>
          )}
          <Button className=" text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none mr-2"
            onClick={() => {
              toast({
                title: t("generation_pdf_started")
              })
              getContractPdf(id, contract.data.contract.contractId).
                then(() => {
                  toast({
                    title: t("generation_pdf_success")
                  })
                })
                .catch((res) => {
                  Sentry.captureException(res);
                  toast({
                    title: t("generation_pdf_error"),
                    variant: "destructive"
                  })
                })
            }} > PDF</Button>
          {userRole !== 'CLIENT' && (
            <Button className=" text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
              onClick={() => {
                navigate(`/contracts/${id}/edit`)
              }}>
              {t('button_change')}
            </Button>
          )}
        </div>
      </div>
    </div>
    <div className='w-full py-8'>
      <div className=" text-2xl ">
        {contract.data.contract.contractId}
      </div>
      <div className="w-full flex align-center">
        <ContractForm
          disabled={true}
          client={contract.data.contract.client}
          signatory={contract.data.contract.signatory}
          contractType={contract.data.contract.contractType}
          conclusionDate={new Date(contract.data.contract.conclusionDate)}
          startDate={new Date(contract.data.contract.startDate)}
          endDate={new Date(contract.data.contract.endDate)}
          grossAmount={contract.data.contract.grossAmount}
          taxDeductible={contract.data.contract.taxDeductible}
          pit={contract.data.contract.pit}
          subjectMetter={contract.data.contract.subjectMatter}
          student={contract.data.contract.student}
          contractState={contract.data.contract.state}
          paidAmount={contract.data.contract.bills.reduce((accumulator, current) => accumulator + (+current.grossAmount), 0)}
          actualGrossAmount={contract.data.contract.actualGrossAmount}
          actualEndDate={contract.data.contract.actualEndDate}
          youngDiscount={contract.data.contract.youngDiscount}
          declarationLanguage={contract.data.contract.declarationLanguage}
          contractPayType={contract.data.contract.contractPayType}
          denouementDate={new Date(contract.data.contract.denouementDate)}
          dontUseStartDate={contract.data.contract.dontUseStartDate}
          startText={contract.data.contract.startText}
          representativeCompany={contract.data.contract.representativeCompany}
        />
        {contract.data.contract.annexes.length > 0 &&
          <div className="w-6/12 pl-10">
            <div className="text-xl font-medium">Aneks</div>
            <div className="pt-[46px]">
              <AnnexView contract={contract.data.contract} />
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}
