import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "../ui/button"
import { cn } from "@/lib/utils"
export function BaseDialog({ className, buttonText, icon, dialogTitle, children, open, setOpen, ommitStyles = false }) {
  const classes = ommitStyles ? '' : 'text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none';
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className={cn(`mr-2 ${classes}`, className)}>{icon}{buttonText} </Button>
      </DialogTrigger>
      <DialogContent onOpenAutoFocus={(e) => {
        e.preventDefault()
      }} className="w-max ">
        <div className="min-h-0 max-h-screen pt-4 pb-4 overflow-y-auto">
          <DialogHeader>
            <DialogTitle>{dialogTitle}</DialogTitle>
          </DialogHeader>
          {children}
        </div>
      </DialogContent>
    </Dialog >
  )
}
