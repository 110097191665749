import { getClients, getContractors, getOneClient, getRateToPLN, getUser } from "@/service/weexpertService";
import { useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import { Search } from "../ui/combobox-search";
import { InputWithAlert } from "../ui/input-with-alert";
import { INVOICE_TYPES, INVOICE_TYPE, transformFromIdValueToValueLabel, STATUS, GET_COUNTRY_NAME_BY_CODE, PAYMENT_NOT_COMPLETED, PAYMENT_METHOD_BANK_ACCOUNT, TRANSACTION_TYPE_LOCAL, TRANSACTION_TYPE, SUPPORTED_CURRENCIES, PAYMENT_STATUS, PAYMENT_METHOD, ROLES } from '../../constants';
import { SelectWithAlert } from "../ui/select-with-alert";
import { DatePicker } from "../ui/date-picker";
import { add, endOfMonth, format, isBefore, startOfMonth, sub } from "date-fns";
import { Textarea } from "../ui/textarea";
import { PositionBlock } from "../admin-page-components/admin-create-components/invoice-create-components/PositionBlock";
import { Button } from "../ui/button";
import { useMutation, useQuery } from "react-query";
import { Switch } from "../ui/switch";
import { Loader } from "../ProtectedRoute";

export const INVOICE_EDIT = 'INVOICE_EDIT';
export const INVOICE_CREATE = 'INVOICE_CREATE';
export const getClientLabel = (client) => {
  return `${client.firstName} ${client.lastName}`;
}
export const InvoiceForm = ({ invoice, action, onSave, inputChecked = true }) => {
  const { t } = useTranslation()
  const empty_error = t('empty_data_invalid');
  const format_error = t('format_data_invalid');


  const [client, setClient] = useState(invoice?.client ?? null);
  const [invoiceType, setInvoiceType] = useState(invoice ? INVOICE_TYPES[invoice.invoiceType] : INVOICE_TYPES.PROFORMA);
  const [issueDate, setIssueDate] = useState(invoice ? new Date(invoice.createdAt) : new Date());
  const [domainDate, setDomainDate] = useState(invoice ? new Date(invoice.domainDate) : new Date());
  const [status, setStatus] = useState(invoice ? invoice.state : "NOT_APPROVED")

  const [counterpartyName, setCounterpartyName] = useState(invoice ? invoice.counterpartyName : null);
  const [counterpartyOrigin, setCounterpartyOrigin] = useState(invoice ? invoice.counterpartyOrigin : null)
  const [counterpartyNip, setCounterpartyNip] = useState(invoice ? invoice.counterpartyNIP : null);
  const [counterpartyEmail, setCounterpartyEmail] = useState(invoice ? invoice.counterpartyEmail : null);
  const [counterpartyType, setCounterpartyType] = useState(invoice ? invoice.counterpartyType : null);
  const [counterpartyAddress, setCounterpartyAddress] = useState(invoice ? invoice.counterpartyAddress : null);
  const [counterpartyPostIndex, setCounterpartyPostIndex] = useState(invoice ? invoice.counterpartyPostIndex : null);
  const [counterpartyCity, setCounterpartyCity] = useState(invoice ? invoice.counterpartyCity : null);
  const [counterpartyCountry, setCounterpartyCountry] = useState(invoice ? invoice.counterpartyCountry : null);

  const [typeOfPayment, setTypeOfPayment] = useState(invoice ? invoice.typeOfPayment : TRANSACTION_TYPE_LOCAL);
  const [wayOfPayment, setWayOfPayment] = useState(invoice ? invoice.wayOfPayment : PAYMENT_METHOD_BANK_ACCOUNT);
  const [paymentStatus, setPaymentStatus] = useState(invoice ? invoice.paymentStatus : PAYMENT_NOT_COMPLETED);

  const [paymentDueDate, setPaymentDueDate] = useState(invoice ? new Date(invoice.paymentDueDate) : add(new Date(), { days: 7 }));
  const [paymentCurrency, setPaymentCurrency] = useState(invoice ? invoice.paymentCurrency : SUPPORTED_CURRENCIES[0].value);
  const [paymentCurrencyRate, setPaymentCurrenyRate] = useState(invoice ? invoice.paymentCurrencyRate : 1.00);

  const [paymentCurrencyRateDate, setPaymentCurrenyRateDate] = useState(invoice && invoice.paymentCurrencyRateDate ? new Date(invoice.paymentCurrencyRateDate) : sub(new Date(), { days: 1 }));
  const [paymentIban, setPaymentIban] = useState(invoice ? invoice.paymentIban : null);

  const [internalComment, setInternalComment] = useState(invoice ? invoice.internalComment : '');
  const [externalComment, setExternalComment] = useState(invoice ? invoice.externalComment : '');
  const [bankAccounts, setBankAccounts] = useState([]);

  const [useDifferentRecipient, setUseDiffrentRecipient] = useState(invoice ? !!invoice.recipientId : false);
  const [recipientCounterpartyName, setRecipientCounterpartyName] = useState(invoice ? invoice.recipient?.name : null);
  const [recipientCounterpartyOrigin, setRecipientCounterpartyOrigin] = useState(invoice ? invoice.recipient?.origin : null)
  const [recipientCounterpartyNip, setRecipientCounterpartyNip] = useState(invoice ? invoice.recipient?.NIP : null);
  const [recipientCounterpartyEmail, setRecipientCounterpartyEmail] = useState(invoice ? invoice.recipient?.email : null);
  const [recipientCounterpartyType, setRecipientCounterpartyType] = useState(invoice ? invoice.recipient?.counterpartyType : null);
  const [recipientCounterpartyAddress, setRecipientCounterpartyAddress] = useState(invoice ? invoice.recipient?.address : null);
  const [recipientCounterpartyPostIndex, setRecipientCounterpartyPostIndex] = useState(invoice ? invoice.recipient?.postIndex : null);
  const [recipientCounterpartyCity, setRecipientCounterpartyCity] = useState(invoice ? invoice.recipient?.city : null);
  const [recipientCounterpartyCountry, setRecipientCounterpartyCountry] = useState(invoice ? invoice.recipient?.country : null);


  const [rows, setRows] = useState(invoice ? invoice.invoiceItems.map(e => {
    return {
      id: e.id,
      name: e.name,
      GTU: e.GTU,
      pkwiu: e.pkwiu,
      quantity: e.amount,
      unit: e.unit,
      unitPriceNetto: e.priceNetto,
      priceNetto: e.priceNetto * e.amount,
      vatRate: e.typeOfVat,
      vat: e.vat * e.amount,
      priceBrutto: e.priceBrutto * e.amount,
    }
  }) : []);

  const [invoiceTotalNetto, setInvoiceTotalNetto] = useState(invoice ? invoice.invoiceTotalNetto : 0.00);
  const [invoiceBrutto, setInvoiceBrutto] = useState(invoice ? invoice.invoiceBrutto : 0.00);
  const [invoiceVAT, setInvoiceVat] = useState(invoice ? invoice.invoiceVAT : 0.00);

  const [newInvoiceId, setNewInvoiceId] = useState(invoice ? invoice?.invoiceId : null);

  const { data: user, isLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })


  useEffect(() => {
    if (paymentCurrency == 'PLN') {
      setPaymentCurrenyRate(1.00);
      return
    }
    getRateToPLN(paymentCurrency, format(paymentCurrencyRateDate, "yyyy-MM-dd"))
      .then(res => {
        setPaymentCurrenyRate(res.data.midRate)
      }).catch((e) => {
        console.log(e)
      })
  }, [paymentCurrencyRateDate, paymentCurrency]);

  useEffect(() => {
    if (client && (client.id != '') && user.data.roles !== 'CLIENT') {
      getOneClient(+client.id)
        .then(res => {
          const incubatorAccounts = res.data.bankAccounts.filter(account => account.accountType === "INCUBATOR");
          setBankAccounts(incubatorAccounts);
        });
    }
  }, [client]);

  useEffect(() => {
    if (user.data.roles === 'CLIENT') {
      setClient({ id: user.data.id, firstName: user.data.firstName, lastName: user.data.lastName })
      const incubatorAccounts = user.data.bankAccounts.filter(account => account.accountType === "INCUBATOR");
      setBankAccounts(incubatorAccounts);
    }
  }, [isLoading])

  useEffect(() => {
    let netto = 0;
    let brutto = 0;
    let vat = 0;
    rows.forEach(e => {
      netto += e.priceNetto;
      brutto += e.priceBrutto;
      vat += e.vat
    });
    setInvoiceBrutto(brutto);
    setInvoiceTotalNetto(netto);
    setInvoiceVat(vat);
  }, [rows])


  const dateOfCreationRestrictionForStuff = (action === INVOICE_EDIT ? [{ before: startOfMonth(issueDate), after: endOfMonth(issueDate) }] : []);
  const dateOfCreationRestrictionForClient = [{ before: new Date(), after: new Date() }];
  const dateOfCreationRestriction = user.data.roles === ROLES.CLIENT ? dateOfCreationRestrictionForClient : dateOfCreationRestrictionForStuff;

  const canChangeCountertyOrRecipient = (action === INVOICE_CREATE) || (user.data.roles !== ROLES.CLIENT);

  const mutation = useMutation({
    mutationFn: () => {
      const recipientCounterparty = {
        counterpartyName: recipientCounterpartyName,
        counterpartyOrigin: recipientCounterpartyOrigin,
        counterpartyNip: recipientCounterpartyNip,
        counterpartyEmail: recipientCounterpartyEmail,
        counterpartyType: recipientCounterpartyType,
        counterpartyAddress: recipientCounterpartyAddress,
        counterpartyPostIndex: recipientCounterpartyPostIndex,
        counterpartyCity: recipientCounterpartyCity,
        counterpartyCountry: recipientCounterpartyCountry,
      }
      const formated_payment_due_day = format(paymentDueDate, 'yyyy-MM-dd');
      const formated_issue_day = format(issueDate, 'yyyy-MM-dd');
      const formated_domain_day = format(domainDate, 'yyyy-MM-dd');
      const formated_exchange_day = format(paymentCurrencyRateDate, 'yyyy-MM-dd');
      return onSave(
        {
          client,
          invoiceType,
          issueDate: formated_issue_day,
          domainDate: formated_domain_day,
          status,
          counterpartyName,
          counterpartyOrigin,
          counterpartyNip,
          counterpartyEmail,
          counterpartyType,
          counterpartyAddress,
          counterpartyPostIndex,
          counterpartyCity,
          counterpartyCountry,
          typeOfPayment,
          wayOfPayment,
          paymentStatus,
          paymentDueDate: formated_payment_due_day,
          paymentCurrency,
          paymentCurrencyRate,
          paymentCurrencyRateDate: formated_exchange_day,
          paymentIban,
          internalComment,
          externalComment,
          rows,
          invoiceBrutto,
          invoiceTotalNetto,
          invoiceVAT,
          newInvoiceId: (invoice && newInvoiceId !== invoice?.invoiceId) ? newInvoiceId : null,
          useDifferentRecipient,
          recipientCounterparty
        }
      )
    },
  });

  if (isLoading) {
    return <Loader />
  }
  return <>
    <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
      <div className="flex justify-between py-8 items-center ">
        <div className=" w-5/12  text-4xl ">
          {action === INVOICE_CREATE ? (t("invoices:create_title")) : (t("invoices:edit_title"))}
        </div>
        <div className=''>
          <Button className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
            disabled={!mutation.isIdle}
            onClick={() => {
              if (onSave) {
                mutation.mutate()
              }
            }}>
            {t("common:button_save")}
          </Button>
        </div>
      </div>
    </div>
    <div className="w-full pb-6 pt-6">
      <div className='w-9/12'>

        <div className='w-full'>
          <div className="w-full text-xl font-medium pb-6 ">{t("common:main_information_title")}</div>
          <div className='w-full flex justify-between'>
            <div className={action === INVOICE_CREATE && user.data.roles !== ROLES.CLIENT ? 'w-[49%]' : (action === INVOICE_EDIT ? 'w-[49%]' : 'hidden')}>
              {action === INVOICE_EDIT && (
                <InputWithAlert
                  label={t('invoices:document')}
                  value={newInvoiceId}
                  onChange={(e) => { setNewInvoiceId(e.target.value) }}
                  disabled={!(action === INVOICE_EDIT && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(user.data.roles))}
                  inputClassName={'h-12  mb-4 bg-white'}
                />
              )}

              {action === INVOICE_CREATE && user.data.roles !== ROLES.CLIENT && (<Search
                fetchOption={(q) => {
                  return getClients(100, 0, q)
                    .then((res) => {
                      return res.data.clients
                        .map(client => {
                          return {
                            label: getClientLabel(client),
                            value: client.id,
                            client: client
                          }
                        })
                    })
                }}
                placeholder={t("common:client") + "*"}
                label={t("common:client") + "*"}
                value={client ? getClientLabel(client) : null}
                onChange={(e) => {
                  setClient(e.client)
                }}
                error={inputChecked && !client}
                errorMessage={empty_error}
              />
              )}
              {action === INVOICE_EDIT && (
                <InputWithAlert
                  readOnly={true}
                  label={t("common:client") + "*"}
                  value={client ? getClientLabel(client) : ''}
                  disabled
                  inputClassName={'h-12  mb-4 bg-white'}
                />
              )}
              {user.data.roles !== ROLES.CLIENT && (
                <SelectWithAlert
                  label={t("common:type") + "*"}
                  options={INVOICE_TYPE(t).map(transformFromIdValueToValueLabel)}
                  value={invoiceType ?? ''}
                  onChangeValue={setInvoiceType}
                  disabled={action != INVOICE_CREATE}
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
              )}
            </div>

            <div className='w-[49%]'>
              <DatePicker
                label={t("invoices:date_of_creation")}
                date={issueDate}
                defaultMonth={issueDate}
                setDate={(date) => {
                  if (!date) return
                  setPaymentDueDate(add(date, { days: 7 }))
                  setIssueDate(date)
                  if (isBefore(date, domainDate)) {
                    setDomainDate(date)
                  }
                }
                }
                error={inputChecked && !issueDate}
                disabled={dateOfCreationRestriction}
                errorMessage={empty_error}
                inputClassName={'h-12 text-base mb-4'}
              />

              <DatePicker
                label={t("invoices:date_of_sale")}
                setDate={(date) => {
                  if (!date) return;
                  setDomainDate(date)
                  setPaymentCurrenyRateDate(sub(date, { days: 1 }))
                }
                }
                date={domainDate}
                disabled={{ after: issueDate }}
                defaultMonth={domainDate}
                inputClassName={'h-12 text-base mb-4'}
              />
              {user.data.roles !== "CLIENT" && (
                <SelectWithAlert
                  label={t('status')}
                  options={STATUS(t).map(transformFromIdValueToValueLabel)}
                  value={status ?? ''}
                  onChangeValue={setStatus}
                  error={inputChecked && !status}
                  errorMessage={empty_error}
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />)
              }
            </div>
          </div>
        </div>

        <div className='w-full'>
          <div className="w-full text-xl font-medium py-6 ">
            {t("invoices:counterparty_info")}
          </div>

          <div className='w-full'>
            <div className='w-full flex justify-between'>
              <div className='w-[49%]'>
                {canChangeCountertyOrRecipient ? (<Search
                  className={'pt-[10px]'}
                  queryKey="counterparties"
                  fetchOption={(query) => {
                    if (!client || !client.id) return [];
                    return getContractors(100, 0, query, [{ field: 'client', value: [client.id] }])
                      .then((res) => {
                        return res.data.counterparties
                          .map(counterparty => {
                            return {
                              label: `${counterparty.name}`,
                              value: counterparty.id,
                              counterparty
                            }
                          })
                      })
                  }}
                  placeholder={t("common:counterparty") + "*"}
                  label={t("common:counterparty") + "*"}
                  value={counterpartyName}
                  error={inputChecked && !counterpartyName}
                  errorMessage={empty_error}
                  onChange={(e) => {
                    setCounterpartyName(e.counterparty.name);
                    setCounterpartyCountry(e.counterparty.country);
                    setCounterpartyCity(e.counterparty.city);
                    setCounterpartyPostIndex(e.counterparty.postIndex);
                    setCounterpartyAddress(e.counterparty.address);
                    setCounterpartyName(e.counterparty.name);
                    setCounterpartyOrigin(e.counterparty.origin);
                    setCounterpartyNip(e.counterparty.NIP);
                    setCounterpartyEmail(e.counterparty.email);
                    setCounterpartyType(e.counterparty.counterpartyType);
                  }}
                />)
                  : (<InputWithAlert
                    placeholder={t("phone")}
                    value={counterpartyName ?? ''}
                    label={t("common:counterparty") + "*"}
                    onChange={(e) => {}}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />)
                }

                <InputWithAlert
                  placeholder={t("phone")}
                  value={counterpartyName ?? ''}
                  label={t("common:counterparty") + "*"}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  placeholder={t("counterparties:origin")}
                  value={counterpartyOrigin ?? ''}
                  label={t("counterparties:origin")}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                {counterpartyType === 'COMPANY' && (
                  <InputWithAlert
                    placeholder={"NIP"}
                    label="NIP"
                    value={counterpartyNip ?? ''}
                    onChange={(e) => {}}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                )}
                <InputWithAlert
                  placeholder={"Email"}
                  label="Email"
                  value={counterpartyEmail ?? ''}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />


              </div>
              <div className='w-[49%]'>
                <InputWithAlert
                  placeholder={t("common:type")}
                  label={t("common:type")}
                  value={counterpartyType ?? ''}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  placeholder={t("common:address")}
                  label={t("common:address")}
                  value={counterpartyAddress ?? ''}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  placeholder={t("common:post_index")}
                  label={t("common:post_index")}
                  value={counterpartyPostIndex ?? ''}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  placeholder={t("common:city")}
                  label={t("common:city")}
                  value={counterpartyCity ?? ''}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  placeholder={t("common:country")}
                  label={t("common:country")}
                  value={GET_COUNTRY_NAME_BY_CODE(counterpartyCountry)}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />

              </div>
            </div>
          </div>
        </div>
        <div>
        </div>

        <div className='w-full'>
          <div className="w-full text-xl font-medium py-6 ">
            {t('different_recipient')}
            <Switch className={"ml-3"} checked={useDifferentRecipient} onCheckedChange={setUseDiffrentRecipient} />
          </div>
          {useDifferentRecipient && (<div className='w-full'>
            <div className='w-full flex justify-between'>
              <div className='w-[49%]'>
                {canChangeCountertyOrRecipient ? (
                  <Search
                    className={'pt-[10px]'}
                    queryKey="counterparties"
                    fetchOption={(query) => {
                      if (!client || !client.id) return [];
                      return getContractors(100, 0, query, [{ field: 'client', value: [client.id] }])
                        .then((res) => {
                          return res.data.counterparties
                            .map(counterparty => {
                              return {
                                label: `${counterparty.name}`,
                                value: counterparty.id,
                                counterparty
                              }
                            })
                        })
                    }}
                    placeholder={t("common:counterparty") + "*"}
                    label={t("common:counterparty") + "*"}
                    value={recipientCounterpartyName}
                    error={inputChecked && useDifferentRecipient && !recipientCounterpartyName}
                    errorMessage={empty_error}
                    onChange={(e) => {
                      setRecipientCounterpartyName(e.counterparty.name);
                      setRecipientCounterpartyCountry(e.counterparty.country);
                      setRecipientCounterpartyCity(e.counterparty.city);
                      setRecipientCounterpartyPostIndex(e.counterparty.postIndex);
                      setRecipientCounterpartyAddress(e.counterparty.address);
                      setRecipientCounterpartyName(e.counterparty.name);
                      setRecipientCounterpartyOrigin(e.counterparty.origin);
                      setRecipientCounterpartyNip(e.counterparty.NIP);
                      setRecipientCounterpartyEmail(e.counterparty.email);
                      setRecipientCounterpartyType(e.counterparty.counterpartyType);
                    }}
                  />) : (<InputWithAlert
                    placeholder={t("phone")}
                    value={recipientCounterpartyName ?? ''}
                    label={t("common:counterparty")}
                    onChange={(e) => {}}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />)
                }
               <InputWithAlert
                  placeholder={t("phone")}
                  value={recipientCounterpartyName ?? ''}
                  label={t("common:counterparty") + "*"}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  placeholder={t("counterparties:origin")}
                  value={recipientCounterpartyOrigin ?? ''}
                  label={t("counterparties:origin")}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                {recipientCounterpartyType === 'COMPANY' && (
                  <InputWithAlert
                    placeholder={"NIP"}
                    label="NIP"
                    value={recipientCounterpartyNip ?? ''}
                    onChange={(e) => {}}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                )}
                <InputWithAlert
                  placeholder={"Email"}
                  label="Email"
                  value={recipientCounterpartyEmail ?? ''}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
              </div>
              <div className='w-[49%]'>
                <InputWithAlert
                  placeholder={t("common:type")}
                  label={t("common:type")}
                  value={recipientCounterpartyType ?? ''}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  placeholder={t("common:address")}
                  label={t("common:address")}
                  value={recipientCounterpartyAddress ?? ''}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  placeholder={t("common:post_index")}
                  label={t("common:post_index")}
                  value={recipientCounterpartyPostIndex ?? ''}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  placeholder={t("common:city")}
                  label={t("common:city")}
                  value={recipientCounterpartyCity ?? ''}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  placeholder={t("common:country")}
                  label={t("common:country")}
                  value={GET_COUNTRY_NAME_BY_CODE(recipientCounterpartyCountry)}
                  onChange={(e) => {}}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
              </div>
            </div>
          </div>
          )}
        </div>
        <div className='w-full'>
          <div className="w-full text-xl font-medium py-6 ">
            {t("invoices:payment_title")}
          </div>
          <div className='w-full flex justify-between'>
            <div className='w-[49%]'>
              <SelectWithAlert
                label={t("invoices:transaction") + '*'}
                options={TRANSACTION_TYPE(t).map(transformFromIdValueToValueLabel)}
                value={typeOfPayment ?? ''}
                onChangeValue={setTypeOfPayment}
                error={inputChecked && !typeOfPayment}
                errorMessage={empty_error}
                inputClassName={'h-12 mt-2 mb-4 bg-white'}
              />
              <SelectWithAlert
                label={t("invoices:payment_status") + '*'}
                options={PAYMENT_STATUS(t).map(transformFromIdValueToValueLabel)}
                onChangeValue={setPaymentStatus ?? ''}
                error={inputChecked && !paymentStatus}
                errorMessage={empty_error}
                value={paymentStatus}
                inputClassName={'h-12 mt-2 mb-4 bg-white'}
              />
              <div className="w-full flex justify-between  pt-2 pb-4 ">
                <div className="w-10/12">
                  <DatePicker
                    label={t("invoices:payment_currency_date")}
                    date={paymentCurrencyRateDate}
                    defaultMonth={paymentCurrencyRateDate}
                    disabled={{ after: new Date() }}
                    setDate={(date) => {
                      if (!date) return
                      setPaymentCurrenyRateDate(date)
                    }}
                    inputClassName={'h-12  text-base'}
                  />

                </div>
                <div className="w-2/12 ml-2">
                  <SelectWithAlert
                    label={t("invoices:currency")}
                    options={SUPPORTED_CURRENCIES}
                    onChangeValue={setPaymentCurrency}
                    value={paymentCurrency}
                    error={inputChecked && !paymentCurrency}
                    errorMessage={empty_error}
                    inputClassName={'h-12 bg-white'}
                  />
                </div>
              </div>
              <SelectWithAlert
                label={t("invoices:payment_account") + "*"}
                placeholder={t("invoices:payment_account") + "*"}
                options={bankAccounts.map(o => ({ value: o.account, label: `${o.account} ${o.currency}` }))}
                onChangeValue={(event) => setPaymentIban(event)}
                value={paymentIban}
                error={inputChecked && !paymentIban}
                errorMessage={empty_error}
                inputClassName={'h-12 mt-2 mb-4 bg-white'}
              />

            </div>
            <div className='w-[49%]'>
              <SelectWithAlert
                label={t("invoices:way_of_payment") + '*'}
                options={PAYMENT_METHOD(t).map(transformFromIdValueToValueLabel)}
                value={wayOfPayment}
                onChangeValue={setWayOfPayment}
                error={inputChecked && !wayOfPayment}
                errorMessage={empty_error}
                inputClassName={'h-12 mt-2 mb-4 bg-white'}
              />
              <DatePicker
                label={t("invoices:payment_due_date")}
                date={paymentDueDate}
                defaultMonth={paymentDueDate}
                disabled={{ before: issueDate }}
                setDate={(date) => {
                  if (!date) return
                  setPaymentDueDate(date)
                }}
                inputClassName={'h-12 mt-2 mb-4 text-base'}
              />
              <InputWithAlert
                label={t('invoices:currency_rate_PLN')}
                value={paymentCurrencyRate}
                onChange={(e) => {}}
                disabled
                inputClassName={'h-12 mt-2 mb-4 bg-white'}
              />
            </div>

          </div>
          <div className='w-full flex justify-between'>
            <div className='w-[49%]'>
              <div className='text-xl font-medium pt-14 pb-6'>
                {t("invoices:internal_comment")}
              </div>
              <Textarea
                placeholder={t("invoices:internal_comment")}
                value={internalComment ?? ''}
                onChange={(e) => setInternalComment(e.target.value)}
                className="h-20 bg-white"
              />
            </div>
            <div className='w-[49%]'>
              <div className='text-xl font-medium  pt-14 pb-6'>
                {t("invoices:comment_on_invoice")}
              </div>
              <Textarea
                placeholder={t("invoices:comment_on_invoice")}
                value={externalComment ?? ''}
                onChange={(e) => (setExternalComment(e.target.value))}
                className="h-20 bg-white"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <PositionBlock
          inputChecked={false}
          rows={rows}
          onRowsUpdate={(rows) => { setRows(rows) }}
          error={inputChecked && (rows.length === 0 ||
            rows.some(row => !row.name ||
              !row.quantity ||
              (invoiceType !== INVOICE_TYPES.FAKTURA_KONCOWA && !row.unitPriceNetto) ||
              !row.vatRate ||
              !row.unit
            )
          )}
          errorMessage={rows.length === 0 ? empty_error : format_error}
        />
        <div className="w-full text-xl font-medium py-6 ">
          {t('invoices:summary')}
        </div>
        <InputWithAlert
          placeholder={t("phone")}
          label={t("invoices:price_netto")}
          value={isNaN(invoiceTotalNetto) ? '' : parseFloat(invoiceTotalNetto).toFixed(2)}
          onChange={(e) => {}}
          disabled
          inputClassName={'h-12 mt-2 mb-4 bg-white w-1/3'}
        />
        <InputWithAlert
          label="VAT"
          value={!isNaN(invoiceVAT) ? parseFloat(invoiceVAT).toFixed(2) : ''}
          onChange={(e) => {}}
          disabled
          inputClassName={'h-12 mt-2 mb-4 bg-white w-1/3'}
        />
        <InputWithAlert
          label={t('invoices:price_brutto')}
          value={!isNaN(invoiceBrutto) ? parseFloat(invoiceBrutto).toFixed(2) : ''}
          onChange={(e) => {}}
          disabled
          inputClassName={'h-12 mt-2 mb-4 bg-white w-1/3'}
        />
      </div>
    </div>
  </>
}
