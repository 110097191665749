import { Button } from "@/components/ui/button";
import { Skeleton } from '@/components/ui/skeleton';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from "@/components/ui/table";
import { deleteArtifact, downloadArtifact, getArtifactsForClient } from "@/service/weexpertService";
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable
} from "@tanstack/react-table";
import { format } from "date-fns";
import { DownloadIcon, TrashIcon } from "lucide-react";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from "react-query";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "../ui/use-toast";

const ArtifactsForClientTable = (props) => {

    const { id} = props;
    const { t } = useTranslation();
    const { data: artifactsForClient, isLoading } = useQuery({
        queryKey: ['artefacts', id], queryFn: () => {
            return getArtifactsForClient(id)
        }
    })
    const queryClient = useQueryClient()
    const columns = [
        {
            accessorKey: "id",
            header: "ID",
            cell: ({ row }) => (
                <div>
                    {row.original.id}
                </div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "createdAt",
            header: t("created_at"),
            cell: ({ row }) => (
                <div>
                    {format(new Date(row.original.createdAt), 'dd/MM/yyyy')}
                </div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "artifactType",
            header: t("artifact_type"),
            cell: ({ row }) => (
                <div>
                    {row.original.artifactType}
                </div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "download",
            header: t("button_download"),
            cell: ({ row }) => (

              <Button variant="outline" className="w-10 h-10 p-0" onClick={ () => {
                downloadArtifact(row.original.id).then(() => {
                  queryClient.invalidateQueries(['artefacts', id]);
                })
                .catch(() => {
                  toast({
                    title: "Oops something went wrong",
                    variant: "destructive",
                  });
                })
              } } >
                    <DownloadIcon />
                </Button>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "delete",
            header: t("button_delete"),
            cell: ({ row }) => (
                <Button variant="outline" className="w-10 h-10 p-0"
                  onClick={() => {
                    deleteArtifact(row.original.id)
                      .then(() => {
                        queryClient.invalidateQueries(['artefacts', id]);
                      })
                      .catch(() => {
                        toast({
                          title: "Oops something went wrong",
                          variant: "destructive",
                        });
                      })
                  }}
                >
                  <TrashIcon />
                </Button>
            ),
            key: uuidv4()
        },
    ]

    const table = useReactTable({
        data: isLoading ? Array(30).fill({}) : artifactsForClient?.data,
        columns: isLoading ? columns.map((column) => {
            return {
                ...column,
                cell: () => {
                    return <Skeleton className='h-4 w-[200px]' />
                }
            }
        }) : columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        manualPagination: true,
    });

    if (isLoading) {
        return (
            <div className="py-10">Loading....</div>
        )
    }

    return (
        <div className="w-full bg-grayLightMainBg">
            <div className="rounded-md border p-2">
                {(
                    <Table>
                        <TableHeader>
                            {table.getHeaderGroups().map(headerGroup => (
                                <TableRow key={uuidv4()}>
                                    {headerGroup.headers.map(header => {
                                        return (
                                            <TableHead className="pt-3 pb-3"
                                                key={uuidv4()}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                            </TableHead>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableHeader>
                        <TableBody>
                            {table.getRowModel().rows?.length ? (
                                table.getRowModel().rows.map(row => (
                                    <TableRow
                                        key={row.id}
                                        data-state={row.getIsSelected() && "selected"}
                                    >
                                        {row.getVisibleCells().map(cell => (
                                            <TableCell className="relative text-sm pt-3 pb-3"
                                                key={uuidv4()}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={columns.length}
                                        className="h-24 text-center"
                                    >
                                        {t('no_results')}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                )}
            </div>
        </div>
    );
}
export default ArtifactsForClientTable;
