
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { BaseDialog } from "./dialog"
import { createBill } from "@/service/weexpertService";
import { format } from "date-fns";
import { toast } from "../ui/use-toast";
import { BillForm } from "../forms/bill.form";
import { useNavigate } from "react-router-dom";

export default function CreateBillPopup(props) {

    const { t } = useTranslation();
    const { initialContract } = props
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const onSaved = (
        contract,
        contractType,
        taxDebuctible,
        pit,
        grossAmount,
        emerytalneRentowe,
        chorobowe,
        zdrowotna,
        status,
        bankAccount,
        conclusionDate,
        acceptedDate,
        specificationStage,
        signatory,
        comment,
        billId,
        currency,
        currencyDate
    ) => {
        if (!contract &&
            !bankAccount ||
            (+grossAmount) <= 0) {
            return
        }
        return createBill(contract.id,
            status,
            contractType,
            taxDebuctible,
            pit,
            format(conclusionDate, 'yyyy-MM-dd'),
            +grossAmount,
            emerytalneRentowe,
            chorobowe,
            zdrowotna,
            bankAccount,
            format(acceptedDate, 'yyyy-MM-dd'),
            signatory,
            comment,
            specificationStage ? specificationStage : null,
            currency ? currency : null,
            currencyDate ? format(currencyDate, 'yyyy-MM-dd') : null
        ).then((res) => {
            toast({
                title: t("alerts:succes"),
            })
            navigate(`/bill/view/${res.data.bill.id}`)
        }).catch((res) => {
            console.log(res)
            toast({
                title: t("alerts:error_send_fail"),
                variant: "destructive"
            })
        })
    }
    return (
        <BaseDialog
            dialogTitle={t("bills:create_bill")}
            buttonText={t("bills:create_bill")}
            open={open}
            setOpen={setOpen}
        >
            <div className="w-[720px] h-[400px] px-6 py-0">
                <BillForm
                    className={'w-full'}
                    initialContract={initialContract}
                    onSaved={onSaved}
                />
            </div >
        </BaseDialog>
    );
}
