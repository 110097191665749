import React from "react";
import { Route, Routes } from "react-router-dom";
import { LoginRestore } from '../../components/login-restore-component/LoginRestore';
import { RestorePass } from "../../components/password-restore-component/RestorePass";
import ProtectedRoute from '../../components/ProtectedRoute';
import SideNav from "../../components/SideNav/Sidenav";
import { INVOICE_TYPES } from "../../constants.jsx";
import { getUser } from '../../service/weexpertService';
import { NotFoundPage } from '../../pages/notfound.page';
import MainPage from '../../pages/main.page';
import AccountPage from '../../pages/account.page';
import UsersPage from '../../pages/users.page';
import UserViewPage from '../../pages/user.view.page';
import UserEditPage from '../../pages/user.edit.page';
import ClientsPage from '../../pages/clients.page';
import ClientCreatePage from '../../pages/client.create.page';
import ClientViewPage from '../../pages/client.view.page';
import ClientEditPage from '../../pages/client.edit.page';
import InvoicesPage from '../../pages/invoices.page';
import InvoiceCreatePage from '../../pages/invoice.create.page';
import InvoiceEditPage from '../../pages/invoice.edit.page';
import InvoiceViewPage from '../../pages/invoice.view.page';
import CounterpartiesPage from '../../pages/counterparties.page';
import CounterpartyViewPage from '../../pages/counterparty.view.page';
import CounterpartyEditPage from '../../pages/counterparty.edit.page';
import CounterpartyCreatePage from '../../pages/counterparty.create.page';
import UserCreatePage from '../../pages/user.create.page';
import { FreelancersPage } from '@/pages/freelancers.page';
import ZgodaPage from '@/pages/client.zgoda.page';
import { Toaster } from '@/components/ui/toaster';
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import SharedClientPools from '@/pages/shared-client-pools.page';
import SharedClientPool from '@/pages/shared-client-pool.view.page';
import { ContractsPage } from '@/pages/contracts.page';
import { ContractCreatePage } from '@/pages/contract.create.page';
import { ContractViewPage } from "@/pages/contract.view.page";
import { ContractEditPage } from "@/pages/contract.edit.page";
import { BillsPage } from "@/pages/bills.page";
import { BillsCreatePage } from "@/pages/bill.create.page";
import { BillEditPage } from "@/pages/bill.edit.page";
import { BillViewPage } from "@/pages/bill.view.page";
import { SpecificationCreatePage, } from "@/pages/specification.create.page";
import { SpecificationEditPage } from "@/pages/specification.edit.page";
import { SpecificationViewPage } from "@/pages/specification.view.page";
import { SpecificationsPage } from "@/pages/specifications.page";
import ReportsPage from "@/pages/bank-report/bank-reports.page";
import { ReportCreatePage } from "@/pages/bank-report/bank-report.create.page";
import ReportViewPage from "@/pages/bank-report/bank-report.view.page";
import BankTransactionView from "@/pages/bank-report/bank-transaction.view.page";
import { AllTransactionsForReportPage, MatchedTransactionsForReportPage, UnmatchedTransactionsForReportPage } from "@/pages/bank-report/bank-report-transactions.pages";
import { MatchedReportInvoicesPage, UnmatchedReportInvoicesPage } from "@/pages/bank-report/bank-report-invoices.pages";
import { ProfileCreationPage } from "@/pages/profile/profile.creation.page";
import { CandidatesPage } from "@/pages/candidates/candidates.page";
import CandidatePage from "@/pages/candidates/candidate.page";
import { BankReportPlatformEvidencesPage } from "@/pages/bank-report/bank-report-platform-bank-based.page";
import ReportEditPage from "@/pages/bank-report/bank-report.edit.page";

const queryClient = new QueryClient()

function App() {

  const { data } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false, refetchOnWindowFocus: false })
  return (
    <div className="app">
      <SideNav userData={data?.data}>
        <Routes>
          <Route path="/" element={
            <ProtectedRoute>
              <MainPage />
            </ProtectedRoute>
          } />
          <Route path="/restore" element={<LoginRestore />} />
          <Route path="/restore/newpass/:parameter?" element={<RestorePass />} />
          <Route path="account" element={
            <ProtectedRoute>
              <AccountPage
                userData={data?.data}
              />
            </ProtectedRoute>
          } />

          <Route path='admin/administration' element={
            <ProtectedRoute>
              <UsersPage />
            </ProtectedRoute>
          } />

          <Route path='admin/user/details/:id' element={
            <ProtectedRoute>
              <UserViewPage />
            </ProtectedRoute>
          } />

          <Route path='admin/user/details/edit/:id' element={
            <ProtectedRoute>
              <UserEditPage />
            </ProtectedRoute>
          } />

          <Route path='admin/create/user' element={
            <ProtectedRoute>
              <UserCreatePage />
            </ProtectedRoute>
          } />

          <Route path='admin/clients' element={
            <ProtectedRoute>
              <ClientsPage userData={data?.data} />
            </ProtectedRoute>
          } />

          <Route path='admin/create/client' element={
            <ProtectedRoute>
              <ClientCreatePage />
            </ProtectedRoute>
          } />

          <Route path='admin/client/details/:id' element={
            <ProtectedRoute>
              <ClientViewPage userData={data?.data} />
            </ProtectedRoute>
          } />

          <Route path='admin/client/details/edit/:id' element={
            <ProtectedRoute>
              <ClientEditPage />
            </ProtectedRoute>
          } />

          <Route path='admin/invoices/sales' element={
            <ProtectedRoute>
              <InvoicesPage
                invoiceType={INVOICE_TYPES.SALES_INVOICE}
              />
            </ProtectedRoute>
          } />

          <Route path='admin/invoices/create' element={
            <ProtectedRoute>
              <InvoiceCreatePage />
            </ProtectedRoute>
          } />

          <Route path='admin/invoices/details/:id' element={
            <ProtectedRoute>
              <InvoiceViewPage />
            </ProtectedRoute>
          } />

          <Route path='admin/invoices/details/edit/:id' element={
            <ProtectedRoute>
              <InvoiceEditPage />
            </ProtectedRoute>
          } />

          <Route path='admin/contractors' element={
            <ProtectedRoute>
              <CounterpartiesPage />
            </ProtectedRoute>
          } />

          <Route path='admin/contractors/details/:id' element={
            <ProtectedRoute>
              <CounterpartyViewPage />
            </ProtectedRoute>
          } />

          <Route path='admin/contractors/details/edit/:id' element={
            <ProtectedRoute>
              <CounterpartyEditPage />
            </ProtectedRoute>
          } />

          <Route path='admin/contractors/create' element={
            <ProtectedRoute>
              <CounterpartyCreatePage />
            </ProtectedRoute>
          } />

          <Route path='admin/freelancers' element={
            <ProtectedRoute>
              <FreelancersPage />
            </ProtectedRoute>
          } />
          <Route path='contracts' element={
            <ProtectedRoute>
              <ContractsPage />
            </ProtectedRoute>
          } />

          <Route path='contracts/create' element={
            <ProtectedRoute>
              <ContractCreatePage />
            </ProtectedRoute>
          } />

          <Route path='contracts/:id' element={
            <ProtectedRoute>
              <ContractViewPage />
            </ProtectedRoute>
          } />

          <Route path='contracts/:id/edit' element={
            <ProtectedRoute>
              <ContractEditPage />
            </ProtectedRoute>
          } />

          <Route path='admin/user/client-shared-pool/' element={
            <ProtectedRoute>
              <SharedClientPools />
            </ProtectedRoute>
          } />

          <Route path='admin/user/client-shared-pool/:id' element={
            <ProtectedRoute>
              <SharedClientPool />
            </ProtectedRoute>
          } />

          <Route path='bills' element={
            <ProtectedRoute>
              <BillsPage />
            </ProtectedRoute>
          } />

          <Route path='bill/create' element={
            <ProtectedRoute>
              <BillsCreatePage />
            </ProtectedRoute>
          } />

          <Route path='bill/view/:id' element={
            <ProtectedRoute>
              <BillViewPage />
            </ProtectedRoute>
          } />

          <Route path='bill/edit/:id' element={
            <ProtectedRoute>
              <BillEditPage />
            </ProtectedRoute>
          } />

          <Route path='specifications' element={
            <ProtectedRoute>
              <SpecificationsPage />
            </ProtectedRoute>
          } />

          <Route path='specification/create' element={
            <ProtectedRoute>
              <SpecificationCreatePage />
            </ProtectedRoute>
          } />

          <Route path='specification/view/:id' element={
            <ProtectedRoute>
              <SpecificationViewPage />
            </ProtectedRoute>
          } />

          <Route path='specification/edit/:id' element={
            <ProtectedRoute>
              <SpecificationEditPage />
            </ProtectedRoute>
          } />

          <Route path='reports' element={
            <ProtectedRoute>
              <ReportsPage />
            </ProtectedRoute>
          } />

          <Route path='report/create' element={
            <ProtectedRoute>
              <ReportCreatePage />
            </ProtectedRoute>
          } />

          <Route path='report/edit/:id' element={
            <ProtectedRoute>
              <ReportEditPage/>
            </ProtectedRoute>
          } />

          <Route path='report/view/:id' element={
            <ProtectedRoute>
              <ReportViewPage />
            </ProtectedRoute>
          } />

          <Route path='report/:id/all-transactions' element={
            <ProtectedRoute>
              <AllTransactionsForReportPage />
            </ProtectedRoute>
          } />

          <Route path='report/:id/matched-transactions' element={
            <ProtectedRoute>
              <MatchedTransactionsForReportPage />
            </ProtectedRoute>
          } />

          <Route path='report/:id/matched-invoices' element={
            <ProtectedRoute>
              <MatchedReportInvoicesPage />
            </ProtectedRoute>
          } />

          <Route path='report/:id/unmatched-invoices' element={
            <ProtectedRoute>
              <UnmatchedReportInvoicesPage />
            </ProtectedRoute>
          } />

          <Route path='report/:id/unmatched-transactions' element={
            <ProtectedRoute>
              <UnmatchedTransactionsForReportPage />
            </ProtectedRoute>
          } />

          <Route path='report/:id/platform-evidence' element={
            <ProtectedRoute>
              <BankReportPlatformEvidencesPage/>
            </ProtectedRoute>
          } />

          <Route path='report/transaction/:id' element={
            <ProtectedRoute>
              <BankTransactionView />
            </ProtectedRoute>
          } />

          <Route path='candidates' element={
            <ProtectedRoute>
              <CandidatesPage/>
            </ProtectedRoute>
          } />

          <Route path='candidate/:id' element={
            <ProtectedRoute>
              <CandidatePage/>
            </ProtectedRoute>
          } />
          <Route path='profile/create' element={<ProfileCreationPage />} />
          <Route path='client/zgoda' element={<ZgodaPage />} />
          <Route path='404' element={<NotFoundPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </SideNav>
      <Toaster />
    </div>
  );
}
const AppWrapper = () => {
  return <QueryClientProvider client={queryClient} contextSharing={true}>
    <App />
  </QueryClientProvider>
}
export default AppWrapper;
